import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { SESSION_STORAGE_KEYS } from '@/config/Constants';
import { env } from '@/config/envs';
import { fetcherV2 } from '@/services/fetch.service';
import { getErrorMessage, isLoadedInsideIframe, openCenteredPopupWindow } from '@/utils';
import { useToggle } from '@/utils/hooks';
import storage from '@/utils/storage';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import {
  NextApiError,
  V2AuthIdpResponse,
  V2SessionResponse,
  GA4_EVENT_NAMES,
  GA4_GENERIC_EVENTS,
} from '@smatio/commons';
import { sendGA4Conversion, trackGA4Event } from '../Analytics';
import SignInWithGoogle from '../Button/SignInWithGoogle';
import PhoneTextfield from '../Inputs/PhoneTextfield';
import { MuiSpinner } from '../Spinner';

interface FormValues {
  username: string;
  password?: string;
  sessionId?: string;
  sessionToken?: string;
  givenName?: string;
  familyName?: string;
  phone?: string;
}

interface LoginFormProps {
  callbackUrl?: string;
  invitationId?: string;
  disableEmail?: boolean;
}

function LoginForm({ callbackUrl, invitationId, disableEmail }: LoginFormProps) {
  const { on: isLoading, toggle: setLoading } = useToggle();
  const { on: isNewUser, toggle: setIsNewUser } = useToggle();
  const [passwordType, togglePasswordType] = useState<'Password' | 'Text'>('Password');
  const { push, asPath, query } = useRouter();
  const dynamicLoginUrl = query?.callbackUrl ? String(query?.callbackUrl) : asPath?.split('?')[0];
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    setFocus,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    ...(query.email && {
      defaultValues: { username: query.email && String(query.email).trim().replaceAll(' ', '+') },
    }),
  });

  const postMessageToIframe = () => {
    const url = env.NEXT_PUBLIC_DOMAIN;
    if (window.parent || window.top) {
      if (window.parent) {
        window.parent.postMessage({ type: 'NAVIGATE', url }, '*');
      } else {
        window.top.postMessage({ type: 'NAVIGATE', url }, '*');
      }
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      setLoading(true);
      if (isRegister) {
        if (
          formData.givenName &&
          formData.familyName &&
          formData.phone &&
          formData.username &&
          formData.password
        ) {
          if (formData?.password?.length > 20) {
            return toast.error('Password max length is 20 characters');
          }
          const res = await axios.post<
            | NextApiError
            | { success: true; userId: string; sessionId: string; sessionToken: string }
          >('/api/auth/zitadel/smat/register', formData);

          const { data } = res;
          if (!data?.success || res instanceof AxiosError) {
            throw new Error(
              res instanceof AxiosError
                ? res.response?.data?.error
                : // @ts-ignore wontfix smatio/commons
                  data?.error || 'Something went wrong'
            );
          } else {
            sendGA4Conversion('sign_up', {
              clientId: data?.userId,
              action_type: 'credentials',
            });
            const result = await signIn('credentials', {
              username: formData.username,
              sessionId: data.sessionId,
              sessionToken: data.sessionToken,
              callbackUrl: dynamicLoginUrl || '/',
              redirect: isLoadedInsideIframe() ? false : true,
              ...(invitationId && { invitationId }),
            });
            if (result?.status === 200) {
              postMessageToIframe();
            }
            return;
          }
        }

        const { data } = await axios.post<NextApiError>(
          '/api/auth/zitadel/smat/register',
          formData
        );
        if (data?.success === false) {
          throw new Error(data.error);
        } else {
          setIsNewUser(true);
          setLoading(false);
          toast.success('Please fill in the details to create an account and validate email');
          setTimeout(() => setFocus('password'), 100);
        }
      } else if (isResetPassword) {
        await axios
          .post<{ success: boolean; data: V2SessionResponse }>(
            '/api/auth/zitadel/smat/reset-password',
            formData
          )
          .catch(() => {
            // catch to supress errors, since we don't want the toast to show the error message;
          });
        setLoading(false);
        toast.info(
          'Please verify your email, if it exists in our records, you will receive instructions on how to reset the password'
        );
        return;
      } else {
        if (!formData.sessionToken && !formData.sessionId) {
          const { data, error } = await fetcherV2<{ success: true; data: V2SessionResponse }>(
            '/api/auth/zitadel/smat/session',
            { data: { username: formData?.username }, method: 'POST' }
          );

          if (error) {
            throw new Error(getErrorMessage(error.data.message, 'Login unavailable'));
          }

          setValue('sessionId', data.data.sessionId);
          setValue('sessionToken', data.data.sessionToken);
          setFocus('password');
          setLoading(false);
        }
        if (formData.password && formData.username && formData.sessionId && formData.sessionToken) {
          const result = await signIn('credentials', {
            ...formData,
            callbackUrl: callbackUrl || dynamicLoginUrl || '/',
            redirect: isLoadedInsideIframe() ? false : true,
          });

          trackGA4Event({
            category: GA4_GENERIC_EVENTS.Login,
            action: GA4_EVENT_NAMES[GA4_GENERIC_EVENTS.Login].Login,
          });

          if (result?.status === 200) {
            postMessageToIframe();
          }
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // User not found would land here...
        const error =
          e.response.data?.data?.details?.[0]?.message || e?.response?.data?.error || e?.message;

        toast.error(error);
      } else {
        toast.error(e.message);
      }
      reset();
      setLoading(false);
    }
  };

  const getFadeTransition = (show: boolean): SxProps => {
    return {
      transition: ({ transitions }: Theme) =>
        transitions.create('all', {
          duration: transitions.duration.complex,
          easing: transitions.easing.easeInOut,
        }),
      maxHeight: show ? '22rem' : 0,
      opacity: show ? 1 : 0,
      overflow: 'hidden',
      ...(!show && { mt: '-1rem' }),
    } as SxProps;
  };

  const isResetPassword = query.hasOwnProperty('recoverPassword');
  const isRegister = query.hasOwnProperty('register');

  const onInvalid = (e) => console.debug('onInvalid', e);

  const onOauthLogin = async (idp: 'Google' | 'Linkedin') => {
    try {
      if (dynamicLoginUrl) {
        storage.save(SESSION_STORAGE_KEYS.idpcb, dynamicLoginUrl);
      }

      const { data } = await axios.post<{ success: boolean; data: V2AuthIdpResponse }>(
        '/api/auth/zitadel/idps',
        { idp }
      );
      if (data.success === false) {
      }
      if (data.data.authUrl) {
        if (isLoadedInsideIframe()) {
          openCenteredPopupWindow(data.data.authUrl, 'popup', 400, 400);
        } else {
          push(data.data.authUrl);
        }
      }
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  useEffect(() => {
    setIsNewUser(!!isRegister);
  }, [isRegister]);

  const showPassword = !!watch('sessionId') && !!watch('sessionToken') && !isResetPassword;

  useEffect(() => {
    const values = getValues();
    if (values?.sessionId && values?.sessionToken) {
      reset();
    }
  }, [watch('username')]);

  const loading = isLoading;

  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit(onSubmit, onInvalid)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: { xs: '100%', md: 'inherit' },
      }}
    >
      <TextField
        id={'username'}
        label={'Email'}
        type={'email'}
        variant={'filled'}
        tabIndex={0}
        size="small"
        inputProps={{ 'aria-readonly': disableEmail }}
        InputProps={{ readOnly: disableEmail }}
        {...register('username', { required: true })}
      />
      {!isRegister && (
        <TextField
          id={'password'}
          type={passwordType}
          label={'Password'}
          variant={'filled'}
          size="small"
          sx={getFadeTransition(!!showPassword || !!isNewUser)}
          {...register('password', {
            required: (!!showPassword || !!isNewUser) && !isResetPassword,
            minLength: {
              value: 8,
              message: 'Min length is 8',
            },
          })}
          error={!!errors['password']}
          {...(!!isNewUser && {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() =>
                      togglePasswordType((pv) => (pv === 'Text' ? 'Password' : 'Text'))
                    }
                    edge="end"
                  >
                    {passwordType === 'Text' ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          })}
        />
      )}
      <Fade mountOnEnter unmountOnExit in={!!isNewUser} timeout={1000}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <TextField
            id={'password'}
            type={passwordType}
            label={'Password'}
            variant={'filled'}
            size="small"
            error={!!errors['password']}
            helperText={errors['password']?.message || undefined}
            sx={getFadeTransition(!!showPassword || !!isNewUser)}
            {...register('password', {
              required: !!showPassword || !!isNewUser,
              ...(!!isNewUser && {
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/,
                  message:
                    'Should contain at least one uppercase and lowercase character and one number',
                },
                minLength: {
                  value: 8,
                  message: 'Password min length is 8',
                },
              }),
            })}
            {...(!!isNewUser && {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        togglePasswordType((pv) => (pv === 'Text' ? 'Password' : 'Text'))
                      }
                      edge="end"
                    >
                      {passwordType === 'Text' ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            })}
          />
          <TextField
            id={'givenName'}
            label={'First name'}
            variant={'filled'}
            size="small"
            {...register('givenName', {
              required: !!isNewUser,
            })}
          />
          <TextField
            id={'familyName'}
            label={'Last name'}
            variant={'filled'}
            size="small"
            required={!!isNewUser}
            {...register('familyName', {
              required: !!isNewUser,
            })}
          />
          <PhoneTextfield error={false} name={'phone'} required={!!isNewUser} control={control} />
        </Box>
      </Fade>
      <Button
        type={'submit'}
        variant={'contained'}
        fullWidth
        disabled={loading}
        sx={{
          width: '100%',
          textTransform: 'none',
        }}
      >
        {loading ? (
          <MuiSpinner color={'inherit'} size={20} />
        ) : isRegister ? (
          'Register'
        ) : isResetPassword ? (
          'Reset password'
        ) : !!showPassword ? (
          'Next'
        ) : (
          'Sign in with email'
        )}
      </Button>
      <SignInWithGoogle
        onClick={() => onOauthLogin('Google')}
        sx={{ display: isResetPassword ? 'none' : 'flex' }}
      />
      <Box
        component={'span'}
        role="button"
        sx={{
          marginInline: '0.25rem',
          color: ({ palette }) => palette.text.primary,
          fontSize: '1rem',
          minHeight: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const params = { ...query };
          delete params.recoverPassword;
          if (isRegister) {
            delete params.register;
          } else {
            params.register = 'true';
          }

          push({ pathname: dynamicLoginUrl, query: params }, undefined, {
            shallow: true,
          });
        }}
      >
        {isRegister ? (
          <span className="underlineLink">
            Already a member?{' '}
            <Box component={'b'} sx={{ color: 'primary.main' }}>
              Sign in
            </Box>
          </span>
        ) : (
          <span className="underlineLink">
            New at Smat?{' '}
            <Box component={'b'} sx={{ color: 'primary.main' }}>
              Sign up
            </Box>
          </span>
        )}
      </Box>

      <Box
        sx={{
          gap: '.5rem',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          transition: 'all 0.3s',
          opacity: 0,
          display: showPassword ? 'flex' : 'none',
          pointerEvents: 'none',
          ...(!!showPassword && { opacity: 1, pointerEvents: 'auto' }),
        }}
      >
        <Box
          component={'span'}
          role="button"
          sx={{
            cursor: 'pointer',
            marginInline: '0.25rem',
            color: ({ palette }) => palette.text.primary,
            fontSize: '0.9rem',
            minHeight: '48px',
          }}
          onClick={() =>
            push(
              isResetPassword ? dynamicLoginUrl : `${dynamicLoginUrl}?recoverPassword`,
              undefined,
              { shallow: true }
            )
          }
        >
          {isResetPassword ? <span className="px-2">Sign in</span> : 'Forgot password?'}
        </Box>
      </Box>
    </Box>
  );
}

export default LoginForm;
